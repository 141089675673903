import Caption from 'primitives/Typography/BodyCopy/Caption';
import Container from './primitives/Container';
import H4 from 'primitives/Typography/Headings/H4';
import Transcription from './primitives/Transcription';
import TranscriptionHeader from './primitives/TranscriptionHeader';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { forwardRef } from 'react';
import { getPodcastTranscriptionsEnabled } from 'state/Features/selectors';
import { PodcastTranscriptionError } from './PodcastTranscriptionError';
import { useSelector } from 'react-redux';

const PodcastTranscriptionContainer = forwardRef<
  HTMLDivElement,
  { episodeTranscription?: string; transcriptionAvailable?: boolean }
>(({ episodeTranscription, transcriptionAvailable, ...props }, ref) => {
  const translate = useTranslate();
  const showPodcastTranscriptions = useSelector(
    getPodcastTranscriptionsEnabled,
  );

  if (
    !showPodcastTranscriptions ||
    !transcriptionAvailable ||
    !episodeTranscription
  ) {
    return null;
  }

  return (
    <Container
      {...props}
      css={{ scrollMargin: '5rem' }}
      id="transcription"
      ref={ref}
    >
      <TranscriptionHeader>
        <H4>{translate('Episode Transcript')}</H4>
        <Caption>
          {translate(
            'Available transcripts are automatically generated. Complete accuracy is not guaranteed.',
          )}
        </Caption>
      </TranscriptionHeader>
      {episodeTranscription ?
        <Transcription
          dangerouslySetInnerHTML={{ __html: episodeTranscription }}
        />
      : <Transcription
          css={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <PodcastTranscriptionError />
        </Transcription>
      }
    </Container>
  );
});

export default PodcastTranscriptionContainer;
