import Body2 from 'primitives/Typography/BodyCopy/Body2';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Description = styled(Body2)(({ theme }) => ({
  color: theme.colors.white.primary,
  lineHeight: '2rem',
  paddingTop: '1.8rem',
  button: {
    color: theme.colors.white.primary,
  },
  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    padding: '1.6rem 1rem 0',
    textAlign: 'center',
  },
}));

export default Description;
