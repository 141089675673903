import PodcastTiles from 'components/PodcastTiles/PodcastShowMoreTiles';
import Section from 'components/Section';
import SectionHeader from 'primitives/Typography/Headings/SectionHeader';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { getPopularCategoryPodcasts } from 'state/Podcast/selectors';
import { TILE_RES } from 'components/MediaServerImage';
import { useSelector } from 'react-redux';

type Props = {
  itemSelectedLocation?: string;
  tilesInRow: number;
};

function PopularPodcasts({ itemSelectedLocation = '', tilesInRow }: Props) {
  const translate = useTranslate();
  const popularPodcasts = useSelector(getPopularCategoryPodcasts);

  const header = (
    <SectionHeader hasMobileBottomLink>
      {translate('Popular Podcasts')}
    </SectionHeader>
  );

  return (
    <Section
      dataTest="popular-podcasts"
      hasBorder={false}
      hasMobileBottomLink
      header={header}
    >
      <PodcastTiles
        imgWidth={TILE_RES}
        itemSelectedLocation={itemSelectedLocation}
        podcasts={popularPodcasts}
        tilesInRow={tilesInRow}
      />
    </Section>
  );
}

export default PopularPodcasts;
