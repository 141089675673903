import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const ThumbnailWrapper = styled.div(({ theme }) => ({
  alignItems: 'flex-end',
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 3rem 0',
  width: 'auto',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    padding: '1rem 2.4rem 0',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    padding: '0 2.4rem',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['370'])]: {
    padding: '0 0.5rem',
    width: 'auto',
  },
}));

export default ThumbnailWrapper;
