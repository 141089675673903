import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getCustomLinks,
  getSeedId,
  getSocialMediaLinks,
} from 'state/Podcast/selectors';
import { State } from 'state/types';
import type { Props } from './PodcastSideRail';

const PodcastSideRail = loadable(() => import('./PodcastSideRail'));

export default connect(
  createStructuredSelector<
    State,
    Pick<Props, 'customLinks' | 'seedId' | 'socialMediaLinks'>
  >({
    customLinks: getCustomLinks,
    seedId: getSeedId,
    socialMediaLinks: getSocialMediaLinks,
  }),
)(PodcastSideRail);
