import PlayButton from 'components/Player/PlayButtonContainer';
import PlayButtonVariantC from 'components/Player/PlayButton/PlayButtonVariantC';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PodcastHeroPlayButtonWrapper from './primitives/PodcastHeroPlayButtonWrapper';
import usePlayerState from 'contexts/PlayerState/usePlayerState';
import { getCurrentPodcastEpisodes } from 'state/Podcast/selectors';
import { PlaybackTypeValue, STATION_TYPE } from 'constants/stationTypes';
import { PLAYER_STATE } from 'constants/playback';
import { useSelector } from 'react-redux';

function PlayNewestButton() {
  const playerState = usePlayerState();
  const episodes = useSelector(getCurrentPodcastEpisodes);

  if (!episodes.length) return null;

  const latestEpisode = episodes.reduce(
    (last, latest) => (last.startDate > latest.startDate ? last : latest),
    episodes[0],
  );
  const { id: episodeId, podcastId, title } = latestEpisode;
  return (
    <PodcastHeroPlayButtonWrapper>
      <PlayButton
        ButtonComponent={PlayButtonVariantC}
        deferPlay={false}
        key={episodeId}
        playedFrom={PLAYED_FROM.PROF_PLAYLIST_PLAY_HERO}
        playingState={playerState?.playbackState ?? PLAYER_STATE.IDLE}
        seedId={episodeId}
        stationId={podcastId}
        stationType={STATION_TYPE.PODCAST as PlaybackTypeValue}
        trackId={episodeId}
        trackName={title}
      />
    </PodcastHeroPlayButtonWrapper>
  );
}
export default PlayNewestButton;
