import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import { BREAKPOINTS } from 'constants/responsive';

const HeroWrapper = styled('section')(({ theme }) => ({
  display: 'flex',
  margin: '0 auto',
  maxWidth: BREAKPOINTS.X4LARGENEW,
  padding: '3.6rem 0 3rem',
  position: 'relative',
  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    flexDirection: 'column',
    padding: '1.6rem 0',
  },
}));

export default HeroWrapper;
