import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import MediaServerImage from 'components/MediaServerImage';
import styled from '@emotion/styled';

const Thumbnail = styled(MediaServerImage)(({ theme }) => ({
  borderRadius: '0.6rem',
  height: '19.5rem',
  overflow: 'hidden',
  width: '19.5rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    height: '10rem',
    width: '10rem',
    margin: '0 auto',
  },
}));

export default Thumbnail;
