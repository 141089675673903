import Section from 'components/Section';
import SectionHeader from 'primitives/Typography/Headings/SectionHeader';
import ShowMoreTiles from 'components/ShowMoreTiles';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import {
  createNewsTile,
  NEWS_ASPECT_RATIO,
} from 'components/LatestNews/NewsChildren';
import { getContentLink } from 'state/Links/selectors';
import { getCurrentPodcastArticles } from 'state/Podcast/selectors';
import { gravity, ratio } from 'utils/mediaServerImageMaker/opsString';
import { useSelector } from 'react-redux';

const LatestNews = () => {
  const articles = useSelector(getCurrentPodcastArticles);
  const contentLink = useSelector(getContentLink);
  const translate = useTranslate();

  const header = (
    <SectionHeader hasMobileBottomLink>
      {translate('The Latest News')}
    </SectionHeader>
  );

  if (!articles?.length) {
    return null;
  }

  const tilesInRow = 3;
  const tilesData = articles.map(({ payload, summary, slug }) =>
    createNewsTile({
      title: summary.title,
      href: `${contentLink}${slug}/`,
      isSponsored: payload.is_sponsored,
      key: slug || summary.title,
      img: summary.image,
      ops: [gravity('center'), ratio(NEWS_ASPECT_RATIO, 1)],
      translate,
      tilesInRow,
    }),
  );

  return (
    <Section hasBorder hasMobileBottomLink header={header} suppressFirstOfType>
      <ShowMoreTiles
        aspectRatio={NEWS_ASPECT_RATIO}
        tilesData={tilesData}
        tilesInRow={tilesInRow}
        titleLines={4}
      />
    </Section>
  );
};

export default LatestNews;
