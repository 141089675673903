import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const InfoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  [mediaQueryBuilder(theme.mediaQueries.max.width['899'])]: {
    width: '60%',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    width: '100%',
  },
}));

export default InfoWrapper;
