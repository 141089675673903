import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const PodcastHeroPlayButtonWrapper = styled('div')(({ theme }) => ({
  button: {
    height: '3rem',
    minWidth: '14rem',
  },
  alignItems: 'center',
  marginRight: '1rem',
  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    margin: '1rem auto 0',
  },
  'button svg': {
    marginRight: '0.8rem',
  },
}));

export default PodcastHeroPlayButtonWrapper;
