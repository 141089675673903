import FilledButton from 'primitives/Buttons/FilledButton';
import Follow from 'styles/icons/Follow';
import InfoButtonWrapper from './primitives/InfoButtonWrapper';
import Menu from 'components/Tooltip/Menu';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import PlayheadPosition from 'components/Tooltip/PlayheadPosition';
import PlayNewestButton from './PlayNewestButton';
import Share from 'styles/icons/Share';
import ShouldShow from 'components/ShouldShow';
import theme from 'styles/themes/default';
import ThumbnailWrapper from './primitives/ThumbnailWrapper';
import Truncate from 'components/Truncate';
import useDynamicBackground from 'hooks/useDynamicBackground';
import useMount from 'hooks/useMount';
import usePlayerActions from 'components/Player/PlayerActions/usePlayerActions';
import usePlayerState from 'contexts/PlayerState/usePlayerState';
import usePodcastHeroState from './usePodcastHeroState';
import useTrackers from 'contexts/Trackers/hooks/useTrackers';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { ConnectedModals } from 'state/UI/constants';
import {
  Description,
  FollowButtonWrapper,
  HeroHeader,
  HeroWrapper,
  InfoWrapper,
  ShareWrapper,
  Thumbnail,
} from './primitives';
import { Events } from 'modules/Analytics';
import { fit, urlOps } from 'utils/mediaServerImageMaker/opsString';
import {
  openModal,
  openSignupModal,
  showFollowedChangedGrowl,
} from 'state/UI/actions';
import { parse } from 'qs';
import { requestFollowed, updateFollowed } from 'state/Podcast/actions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useRef } from 'react';
import { WIDGET_DIMENSIONS } from 'constants/widgetDimensions';

function PodcastHero() {
  const playerActions = usePlayerActions();
  const playerState = usePlayerState();
  const state = usePodcastHeroState();
  const dispatch = useDispatch();
  const location = useLocation();
  const translate = useTranslate();
  const trackers = useTrackers();
  const wrapperRef = useRef<HTMLElement | null>(null);

  const [loadImage, backgroundCss] = useDynamicBackground(70);

  const isCurrentPodcast =
    String(playerState?.stationId) === String(state.seedId);

  function handleUpdateFollowed(): void {
    if (state.stationSoftgate.podcast && state.isLoggedOut) {
      dispatch(openSignupModal({ context: 'reg-gate' }));
    } else {
      dispatch(
        updateFollowed({
          followed: !state.followed,
          seedId: state.seedId,
        }),
      );

      // Braze Tracking event both registered and anonymous user
      trackers.track(
        state.followed ? Events.UnfollowPodcast : Events.FollowPodcast,
        {
          podcast: state.title,
          podcastId: state.seedId,
        },
      );

      trackers.track(Events.Subscribe, {
        id: state.seedId,
        name: state.title,
        type: state.seedType,
      });

      dispatch(
        showFollowedChangedGrowl({
          isFollowed: !state.followed,
          name: state.title,
        }),
      );
    }
  }

  function share(): void {
    trackers.track(Events.Share, {
      seedType: state.seedType,
      seedId: state.seedId,
      stationName: state.title,
    });

    dispatch(
      openModal({
        id: ConnectedModals.Share,
        context: {
          seedType: state.seedType,
          seedId: state.seedId,
          url: state.pathname,
          stationName: state.title,
          hideDescription: true,
          dimensions: WIDGET_DIMENSIONS.PODCAST_PROFILE,
        },
      }),
    );
  }

  useMount(() => {
    async function handleFollow(): Promise<void> {
      await dispatch(requestFollowed());
      const query = parse(location.search.slice(1));
      if (state.followed || query.follow !== 'true') return;
      handleUpdateFollowed();
    }

    handleFollow();
  });

  return (
    <div css={{ background: theme.colors.black.primary }}>
      <div css={backgroundCss}>
        <HeroWrapper data-test="hero-container" ref={wrapperRef}>
          <ThumbnailWrapper>
            <Thumbnail
              alt={state.title}
              crossOrigin
              data-test="podcast-profile-hero-image"
              onLoad={loadImage}
              ops={[urlOps(state.imgUrl), fit(480, 480)]}
              src={state.imgUrl}
            />
          </ThumbnailWrapper>
          <InfoWrapper>
            <HeroHeader data-test="podcast-profile-heading">
              {state.title}
            </HeroHeader>
            <InfoButtonWrapper>
              <PlayNewestButton />
              <FollowButtonWrapper>
                <ShouldShow shouldShow={state.followed}>
                  <FilledButton
                    data-test="podcast-hero-follow-button"
                    marginRight="1rem"
                    onClick={handleUpdateFollowed}
                    size="small"
                    styleType="light"
                  >
                    {translate('Following')}
                  </FilledButton>
                </ShouldShow>
                <ShouldShow shouldShow={!state.followed}>
                  <OutlinedButton
                    data-test="podcast-hero-follow-button"
                    marginRight="1rem"
                    onClick={handleUpdateFollowed}
                    size="small"
                    styleType="light"
                  >
                    <Follow />
                    {translate('Follow')}
                  </OutlinedButton>
                </ShouldShow>
                <Menu
                  target={
                    <ShareWrapper>
                      <Share
                        color={theme.colors.white.primary}
                        data-test="podcast-hero-share-button"
                        height="18"
                      />
                    </ShareWrapper>
                  }
                >
                  <Menu.List>
                    <Menu.Item
                      onClick={() => {
                        share();
                      }}
                    >
                      {translate('Share Podcast')}
                    </Menu.Item>
                    {playerState?.trackId !== null && isCurrentPodcast && (
                      <Menu.Item
                        onClick={() => {
                          playerActions.share();
                        }}
                      >
                        {translate('Share Episode')}
                      </Menu.Item>
                    )}
                    {playerState?.trackId !== null && isCurrentPodcast && (
                      <Menu.Item
                        onClick={() => {
                          playerActions.share(true);
                        }}
                      >
                        {translate('Share from')} <PlayheadPosition />
                      </Menu.Item>
                    )}
                  </Menu.List>
                </Menu>
              </FollowButtonWrapper>
            </InfoButtonWrapper>
            <Description>
              <Truncate lines={3} shouldExpand>
                {state.description}
              </Truncate>
            </Description>
          </InfoWrapper>
        </HeroWrapper>
      </div>
    </div>
  );
}

export default PodcastHero;
