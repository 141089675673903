import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const ShareButtonWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  float: 'right',
  margin: 'auto 0',
  [mediaQueryBuilder(theme.mediaQueries.max.width['899'])]: {
    position: 'absolute',
    right: '2rem',
    top: '1.2rem',
  },
}));

export default ShareButtonWrapper;
