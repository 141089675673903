import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getCurrentPodcastEpisode,
  getImgUrl,
  getPodcastEpisodePath,
  getTitle,
} from 'state/Podcast/selectors';
import { getSiteUrl } from 'state/Config/selectors';
import type { Props } from './PodcastEpisodeHead';
import type { State } from 'state/types';

const PodcastEpisodeHead = loadable(() => import('./PodcastEpisodeHead'));

export default connect(
  createStructuredSelector<State, Omit<Props, 'description'>>({
    episode: getCurrentPodcastEpisode,
    imgUrl: getImgUrl,
    pathName: getPodcastEpisodePath,
    showTitle: getTitle,
    siteUrl: getSiteUrl,
  }),
)(PodcastEpisodeHead);
