import FilledButton from 'primitives/Buttons/FilledButton';
import Play from 'styles/icons/Play';
import useTheme from 'contexts/Theme/useTheme';
import useTranslate from 'contexts/TranslateContext/useTranslate';

type Props = {
  onClick: () => void;
  svgFillColor?: string;
};

export default function PlayButtonVariantC({ onClick, svgFillColor }: Props) {
  const translate = useTranslate();
  const theme = useTheme();
  const fill = svgFillColor ?? theme.colors.white.primary;

  return (
    <FilledButton
      customSVGStyles={{ fill, height: '1rem', width: '1rem' }}
      onClick={onClick}
      size="small"
    >
      <Play />
      <span>{translate('Play Newest')}</span>
    </FilledButton>
  );
}
