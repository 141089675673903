import Body1 from 'primitives/Typography/BodyCopy/Body1';
import colors from 'styles/colors';
import FilledButton from 'primitives/Buttons/FilledButton';
import H4 from 'primitives/Typography/Headings/H4';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import WarningOutline from 'styles/icons/WarningOutline';

export const PodcastTranscriptionError = () => {
  const translate = useTranslate();

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <WarningOutline />
      <H4 css={{ paddingTop: '1.6rem' }} textAlign="center">
        {translate('Error Loading Transcript')}
      </H4>
      <Body1 color={colors.gray[500]} textAlign="center">
        {translate(
          'We had a problem rendering this page, please try refreshing your browser',
        )}
      </Body1>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.6rem',
          paddingTop: '2.4rem',
        }}
      >
        <FilledButton onClick={() => window.location.reload()}>
          {translate('Refresh the page')}
        </FilledButton>
        <a href="https://help.iheart.com" rel="noreferrer" target="_blank">
          <OutlinedButton>{translate('Report this issue')}</OutlinedButton>
        </a>
      </div>
    </div>
  );
};
