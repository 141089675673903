type Props = {
  'data-test'?: string;
};

function WarningOutline({ ...props }: Props) {
  return (
    <svg
      fill="none"
      height="96"
      viewBox="0 0 96 96"
      width="96"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M8 48C8 25.92 25.92 8 48 8C70.08 8 88 25.92 88 48C88 70.08 70.08 88 48 88C25.92 88 8 70.08 8 48ZM16 48C16 65.68 30.32 80 48 80C65.68 80 80 65.68 80 48C80 30.32 65.68 16 48 16C30.32 16 16 30.32 16 48ZM48 52C45.8 52 44 50.2 44 48V32C44 29.8 45.8 28 48 28C50.2 28 52 29.8 52 32V48C52 50.2 50.2 52 48 52ZM48 68C45.7909 68 44 66.2091 44 64C44 61.7909 45.7909 60 48 60C50.2091 60 52 61.7909 52 64C52 66.2091 50.2091 68 48 68Z"
        fill="#A9AFB2"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default WarningOutline;
