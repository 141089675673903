import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const FollowButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '3rem',
  alignItems: 'center',
  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    button: {
      margin: '1rem auto 0',
    },
    flexDirection: 'column',
  },
}));

export default FollowButtonWrapper;
