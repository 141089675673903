import styled from '@emotion/styled';

const Transcription = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.white.primary,
  borderRadius: '0.3rem',
  display: 'block',
  height: '35rem',
  overflowWrap: 'anywhere',
  overflowY: 'auto',
  padding: '1.8rem ',
  whiteSpace: 'pre-line',
  width: '100%',

  '.podcast-transcription-speaker': {
    fontWeight: 'bold',
  },
}));

export default Transcription;
