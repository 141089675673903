import loadable from '@loadable/component';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getDescription,
  getImgUrl,
  getPodcastPath,
  getSeedId,
  getSlug,
  getTitle,
} from 'state/Podcast/selectors';
import { getSiteUrl } from 'state/Config/selectors';
import { withRouter } from 'react-router-dom';

const PodcastEpisodesHead = loadable(() => import('./PodcastEpisodesHead'));

export default compose(
  connect(
    createStructuredSelector({
      description: getDescription,
      imgUrl: getImgUrl,
      pathname: getPodcastPath,
      seedId: getSeedId,
      siteUrl: getSiteUrl,
      slug: getSlug,
      title: getTitle,
    }),
  ),
  withRouter,
)(PodcastEpisodesHead);
