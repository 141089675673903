import H1 from 'primitives/Typography/Headings/H1';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const HeroHeader = styled(H1)(({ theme }) => ({
  color: theme.colors.white.primary,
  fontSize: theme.fonts.size['32'],
  lineHeight: theme.fonts.lineHeight['30'],
  marginTop: '1rem',
  paddingRight: '2rem',
  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    fontSize: theme.fonts.size['24'],
    lineHeight: theme.fonts.lineHeight['26'],
    margin: '.5rem',
    padding: '.5rem 1rem 0',
    textAlign: 'center',
  },
}));

export default HeroHeader;
